import { useContext, useEffect, useRef } from 'react';
import { InstagramLogo, LinkedinLogo, TiktokLogo } from '@assets/svgComponents';
import { GlobalContext } from '../context/globalContext';

function MemberCard({
  image,
  name,
  insta,
  linkedin,
  tiktok,
  description,
}: {
  image: string;
  name: string;
  insta: string | null;
  linkedin: string | null;
  tiktok: string | null;
  description: string;
}): JSX.Element {
  const { isMobile } = useContext(GlobalContext);
  const blobRef = useRef<HTMLDivElement>(null);
  const fakeblobRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseMove = (ev: MouseEvent) => {
      if (blobRef.current && fakeblobRef.current) {
        const rec = fakeblobRef.current.getBoundingClientRect();
        blobRef.current.style.opacity = '1';
        blobRef.current.style.transform = `translate(${
          ev.clientX - rec.left - rec.width / 2
        }px,${ev.clientY - rec.top - rec.height / 2}px)`;
      }
    };
    if (!isMobile) {
      window.addEventListener('mousemove', handleMouseMove);
    }
    return () => {
      if (!isMobile) {
        window.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, []);

  return (
    <div className="member-card">
      <img
        src={image}
        alt={name}
        className="member-card-inner w-full h-full object-cover md:transition-all md:duration-100 md:ease-linear"
      />
      <div className="absolute inset-x-0 bottom-0 p-4 flex flex-col h-[12rem] will-change-transform">
        <div className="flex-grow-0">
          <h2 className="text-3xl font-medium">
            {name}
            {insta && (
              <a
                className="social-members"
                target="_blank"
                href={insta}
                rel="noreferrer"
              >
                <InstagramLogo height="1rem" width="1rem" />
              </a>
            )}
            {linkedin && (
              <a
                className="social-members"
                target="_blank"
                href={linkedin}
                rel="noreferrer"
              >
                <LinkedinLogo height="1rem" width="1rem" />
              </a>
            )}
            {tiktok && (
              <a
                className="social-members"
                target="_blank"
                href={tiktok}
                rel="noreferrer"
              >
                <TiktokLogo height="1rem" width="1rem" />
              </a>
            )}
          </h2>
        </div>
        <div className="flex-grow mt-5 overflow-hidden">
          {description && <p>{description}</p>}
        </div>
      </div>
      {!isMobile && (
        <>
          <div className="blob" ref={blobRef} />
          <div className="fakeblob" ref={fakeblobRef} />
        </>
      )}
    </div>
  );
}

export default MemberCard;
