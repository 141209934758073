import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { GlobalContext } from '../context/globalContext';

function NeonEffect() {
  const { isMobile } = useContext(GlobalContext);
  return !isMobile ? (
    <>
      <div className="neon-container flex">
        <div className="flex flex-1 items-end">
          <motion.div
            className="neon-rectangle-initial neon-rectangle-left"
            initial={{ opacity: 1 }}
            animate={{ opacity: 0 }}
            transition={{ duration: 2 }}
          />
        </div>
        <div className="flex flex-1 items-end">
          <motion.div
            className="neon-rectangle-initial neon-rectangle-right"
            initial={{ opacity: 1 }}
            animate={{ opacity: 0 }}
            transition={{ duration: 2 }}
          />
        </div>
      </div>
      <div className="neon-container flex">
        <div className="flex flex-1 items-end">
          <motion.div
            className="neon-rectangle-final neon-rectangle-left"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }}
          />
        </div>
        <div className="flex flex-1 items-end">
          <motion.div
            className="neon-rectangle-final neon-rectangle-right"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }}
          />
        </div>
      </div>
      <div className="darkening-layer" />
    </>
  ) : (
    <>
      <motion.div
        className="neon-mobile-static-layer"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2 }}
      />
      <div className="darkening-layer" />
    </>
  );
}

export default NeonEffect;
