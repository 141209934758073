import ShinyButton from '@components/ShinyButton';
import { CheckCircleIcon, PlusCircleIcon } from '@assets/svgComponents';

export type IOfferPointType = {
  text: string;
  type: 'basic' | 'premium';
};

interface OfferCardProp {
  title: string;
  points: IOfferPointType[];
}
function OfferCard({ title, points }: OfferCardProp): JSX.Element {
  return (
    <div className="md:w-[27.25rem] flex w-full justify-center md:flex-y-grow h-full md:h-auto">
      <div className="md:w-[100%] w-[16.5rem] h-full border border-orange-default rounded-lg offer-card md:p-8 p-5 flex flex-col space-y-8 md:h-auto h-full">
        <div className="text-2xl text-center">{title}</div>
        <ShinyButton
          text="Contactez un conseiller"
          onClick={() => {
            window.open(
              'https://calendly.com/thetruthmentoring/30min',
              '_blank'
            );
          }}
        />
        <div className="flex flex-col md:space-y-6 space-y-4">
          {points.map((point) => (
            <div key={point.text} className="flex space-x-4 items-center">
              <div className="w-[1.5rem]">
                {point.type === 'basic' ? (
                  <CheckCircleIcon
                    width="1.5rem"
                    height="1.5rem"
                    fill="white"
                  />
                ) : (
                  <PlusCircleIcon width="1.5rem" height="1.5rem" fill="white" />
                )}
              </div>

              <div className="md:text-2xl text-xs font-light whitespace-nowrap">
                {point.text}
              </div>
              {point.type === 'premium' && (
                <div className="text-[0.625rem] md:text-sm flex items-center justify-center px-2 py-1 rounded-[2rem] bg-orange-opacity-20 font-light ">
                  Premium
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OfferCard;
