import MemberCard from '@components/MemberCard';
import TitleTag from '@components/TitleTag';
import member_1 from '@assets/images/members/member_1.png';
import member_2 from '@assets/images/members/member_2.jpg';
import member_3 from '@assets/images/members/member_3.jpg';
import ShinyButton from '@components/ShinyButton';
import { useContext, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { generateStars } from './HomeSection';
import { GlobalContext } from '../context/globalContext';
import MembersSectionMobile from './MembersSectionMobile';

const members = [
  {
    image: member_1,
    name: 'Djessim',
    insta: 'https://www.instagram.com/_djessim?igsh=bTVxN2FmMnRpejRs',
    linkedin:
      'https://www.linkedin.com/in/djessim-berrad-7a0ba2292?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
    tiktok: null,
    description:
      "5 ans d'expérience, cofondateur de sa propre structure financière, a coaché plus de 150 élèves, coach sur les cursus Économie, Psychologie et Quantification",
  },
  {
    image: member_2,
    name: 'Ramon',
    insta: 'https://www.instagram.com/ramojet/',
    linkedin:
      'https://www.linkedin.com/in/ramon-jean-baptiste-5a0ba4239?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
    tiktok: null,
    description:
      '2 ans et demi d’expérience dans la finance, des résultats déjà prouvés, coach sur les cursus Technique et Psychologie',
  },
  {
    image: member_3,
    name: 'Younes',
    insta:
      'https://www.instagram.com/younesmedia_?igsh=MXV3bDZwOHFuNDRtZw%3D%3D&utm_source=qr',
    linkedin:
      'https://www.linkedin.com/in/youn%C3%A8s-mamoun-b62127205?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
    tiktok: null,
    description: 'Responsable de la communication et du marketing',
  },
];

function MembersSection(): JSX.Element {
  const { isMobile } = useContext(GlobalContext);
  const divRef = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [opacity, setOpacity] = useState(0);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!divRef.current || isFocused) return;

    const div = divRef.current;
    const rect = div.getBoundingClientRect();

    setPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top });
  };

  const handleFocus = () => {
    setIsFocused(true);
    setOpacity(1);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setOpacity(0);
  };

  const handleMouseEnter = () => {
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setOpacity(0);
  };

  const fadeInAnimationVariants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: { opacity: 1, y: 0 },
  };

  useEffect(() => {
    if (divRef && divRef.current) {
      generateStars(isMobile ? 30 : 50, divRef.current); // 50 étoiles, ajustez selon vos besoins
    }
  }, []);

  return (
    <div
      id="members-section"
      ref={divRef}
      className="flex flex-col items-center md:pt-[7.5rem] pt-[4rem] relative md:pb-[15.625rem]"
    >
      <div className="ellipse-background" />
      {!isMobile && (
        <div
          className="pointer-events-none absolute -inset-px opacity-0 transition duration-300 z-[80]"
          style={{
            opacity,
            background: `radial-gradient(30rem circle at ${position.x}px ${position.y}px, rgba(232,130,20,0.1), transparent 60%)`,
          }}
        />
      )}
      <div className="member-section flex flex-col items-center space-y-12">
        <div className="flex flex-col items-center justify-center space-y-4">
          <TitleTag text="Rencontrez" />
          <div className="text-center md:text-[4rem] text-[2rem] leading-normal md:mt-4 mt-8 font-medium">
            Notre équipe
          </div>
        </div>
        <div
          onMouseMove={handleMouseMove}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="flex md:w-[78rem] w-[100vw] justify-center relative"
        >
          {isMobile ? (
            <MembersSectionMobile members={members} />
          ) : (
            <div className="flex md:space-x-8 space-x-3 items-stretch w-full z-30">
              {members.map((member, i) => (
                <motion.div
                  key={member.name}
                  variants={fadeInAnimationVariants}
                  initial="initial"
                  whileInView="animate"
                  viewport={{ once: true }}
                  transition={{ duration: 1, delay: i * 0.3 }}
                  className="flex flex-1 "
                >
                  <MemberCard
                    image={member.image}
                    name={member.name}
                    insta={member.insta}
                    linkedin={member.linkedin}
                    tiktok={member.tiktok}
                    description={member.description}
                  />
                </motion.div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="contact-logos flex items-center pt-[3rem]">
        <ShinyButton
          text="Get started"
          onClick={() => {
            window.open(
              'https://calendly.com/thetruthmentoring/30min',
              '_blank'
            );
          }}
        />
      </div>
    </div>
  );
}

export default MembersSection;
