import { Link } from 'react-router-dom';
import logoUrl from '@assets/images/logo/the_truth_logo.svg';
import MenuLink from '@components/menu/MenuLink';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useRef, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { GlobalContext } from '../../context/globalContext';

const sections = [
  { id: 'home-section', title: 'Accueil' },
  { id: 'vision-section', title: 'Mission' },
  { id: 'members-section', title: 'Équipe' },
  { id: 'offers-section', title: 'Offres' },
  { id: 'contact-section', title: 'Contact' },
];

const menuVariants = {
  visible: { y: 0, opacity: 0.9, transition: { duration: 0.3 } },
  hidden: { y: '-100%', opacity: 0, transition: { duration: 0.3 } },
};

const menuMobileVariants = {
  open: { opacity: 0.9, height: 'min-content' },
  closed: { opacity: 0, height: 0 },
};

const iconVariants = {
  open: { rotate: 0, transition: { duration: 0.5 } },
  closed: { rotate: -180, transition: { duration: 0.5 } },
};

function Menu(): JSX.Element {
  const { isMobile } = useContext(GlobalContext);
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => setIsOpen(!isOpen);
  const observers: IntersectionObserver[] = [];

  useEffect(() => {
    sections.forEach(({ id }) => {
      const element = document.getElementById(id);

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setActiveSection(id);
            }
          });
        },
        {
          threshold: 0.7,
        }
      );

      if (element) observer.observe(element);

      observers.push(observer);
    });

    return () => {
      observers.forEach((observer) => observer.disconnect());
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(!entry.isIntersecting);
        setIsOpen(false);
      },
      { threshold: 0 }
    );

    if (menuRef.current) {
      observer.observe(menuRef.current);
    }

    return () => {
      if (menuRef.current) {
        observer.unobserve(menuRef.current);
      }
    };
  }, []);

  const renderMenu = (fixed: boolean) => {
    return (
      <div
        className={`z-[100] top-0 w-full ${
          fixed ? 'fixed bg-black-opacity-90' : 'absolute bg-black-default'
        }`}
      >
        <div
          ref={!fixed ? menuRef : null}
          className={`${
            fixed ? 'h-min' : 'md:h-[15.5rem] h-[10.95rem]'
          } md:px-[12.25rem] px-[1.5rem] flex flex-col justify-between items-center`}
        >
          <div
            className={`w-full flex items-center justify-between h-min py-6 ${
              fixed ? 'md:py-0' : 'md:py-8'
            }`}
          >
            <Link to="/">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, rotateY: 360 }}
                transition={{
                  opacity: { duration: 2, delay: 0 },
                  rotateY: {
                    ease: 'linear',
                    duration: 3.5,
                    repeat: Infinity,
                    delay: 0.5,
                  },
                }}
              >
                <img
                  src={logoUrl}
                  alt="logo"
                  className="md:w-28 md:h-28 w-16 h-16"
                />
              </motion.div>
            </Link>
            {!isMobile ? (
              <div className="flex px-8 py-2 justify-center items-center gap-12 rounded-[2rem] border border-orange-opacity-50 bg-[#FFFFFF0D] h-min">
                {sections.map(({ id, title }) => (
                  <MenuLink
                    key={id}
                    anchor={id}
                    title={title}
                    active={id === activeSection}
                  />
                ))}
              </div>
            ) : (
              <button type="button" className="z-40 mr-4" onClick={toggleMenu}>
                <motion.div
                  variants={iconVariants}
                  initial={false}
                  animate={isOpen ? 'open' : 'closed'}
                  className="will-change-transform"
                >
                  {isOpen ? (
                    <CloseIcon sx={{ fontSize: '2rem', color: '#E88214' }} />
                  ) : (
                    <MenuIcon sx={{ fontSize: '2rem', color: '#E88214' }} />
                  )}
                </motion.div>
              </button>
            )}
          </div>
          {!isOpen &&
            !fixed &&
            (isMobile ? (
              <div className="w-[22.375rem] h-[.125rem] bg-orange-default" />
            ) : (
              <motion.div
                className="w-[60rem] h-[.125rem] bg-orange-default"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 2 }}
              />
            ))}
        </div>
        {isMobile && (
          <motion.div
            className="z-[101] overflow-hidden"
            variants={menuMobileVariants}
            initial="closed"
            animate={isOpen ? 'open' : 'closed'}
            transition={{ duration: 0.5 }}
          >
            <div
              className={`flex flex-col overflow-hidden items-center justify-center ${
                fixed ? 'pb-16' : 'pb-32'
              }`}
            >
              {sections.map(({ id, title }) => (
                <MenuLink
                  key={id}
                  anchor={id}
                  title={title}
                  active={id === activeSection}
                  addClass="py-2" // Ajout de padding pour chaque lien
                  onClick={() => setIsOpen(false)}
                />
              ))}
            </div>
          </motion.div>
        )}
      </div>
    );
  };

  return (
    <>
      {renderMenu(false)}
      <motion.div
        initial="hidden"
        animate={isSticky ? 'visible' : 'hidden'}
        variants={menuVariants}
        className={`fixed w-full ${isSticky ? 'z-[110]' : ''}`}
      >
        {renderMenu(true)}
      </motion.div>
    </>
  );
}

export default Menu;
