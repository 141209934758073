import TitleTag from '@components/TitleTag';
import { EmailLogo, WhatsappLogo } from '@assets/svgComponents';

function ContactSection(): JSX.Element {
  return (
    <div
      id="contact-section"
      className="w-full flex flex-col items-center relative overflow-hidden md:pb-[7rem] pb-[4rem] z-40"
    >
      <TitleTag text="Aller plus loin" />
      <div className="text-center space-y-8 mb-[4rem]">
        <div className="md:text-[4rem] text-[2rem] leading-normal md:mt-4 mt-8 font-medium">
          Contactez-nous
        </div>
        <p className="md:text-2xl text-sm font-light md:w-[63rem] w-[20.75rem]">
          Que vous soyez un débutant cherchant à apprendre ou un investisseur
          chevronné vous êtes prêt à embrasser The Truth Mentoring.
        </p>
      </div>
      <div className="w-full flex flex-col items-center md:w-min md:px-0 px-8 space-y-8">
        <div className="contact-logos">
          <a
            className="social-members"
            target="_blank"
            href="mailto:thetruthmentoring@gmail.com"
            rel="noreferrer"
          >
            <EmailLogo height="3rem" width="3rem" />
          </a>
          <a
            className="social-members"
            target="_blank"
            href="https://wa.me/+33756885804"
            rel="noreferrer"
          >
            <WhatsappLogo height="3rem" width="3rem" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ContactSection;
