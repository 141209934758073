interface IMenuLinkProps {
  anchor: string;
  title: string;
  active?: boolean;
  onClick?: () => void;
  addClass?: string;
}

function MenuLink({
  anchor,
  title,
  active,
  onClick,
  addClass,
}: IMenuLinkProps): JSX.Element {
  return (
    <a
      href={`#${anchor}`}
      className={`${
        active
          ? 'md:text-white text-orange-default'
          : 'md:opacity-50 opacity-100 text-white'
      } font-light text-base hover:!opacity-100 w-full text-center ${addClass}`}
      onClick={onClick}
    >
      {title}
    </a>
  );
}

export default MenuLink;

MenuLink.defaultProps = {
  active: false,
  addClass: '',
  onClick: undefined,
};
