import React from 'react';
import { motion } from 'framer-motion';

const randomOpacityValues = Array.from(
  { length: 100 },
  () => Math.random() * 0.3 + 0.1
);

const flickerAnimation = {
  initial: { opacity: 0.4 },
  animate: {
    opacity: randomOpacityValues,
    transition: {
      duration: 60,
      ease: 'linear',
      loop: Infinity,
      repeat: Infinity,
    },
  },
};

function LayerExoPlanete() {
  return (
    <>
      <motion.div
        className="layer-bottom-darkness"
        initial="initial"
        animate="animate"
        variants={flickerAnimation}
      />
      <div className="layer-bottom-ellipse-1" />
      <div className="layer-bottom-ellipse-2" />
      <div className="layer-bottom-ellipse-3" />
      <div className="blur-container">
        <div className="layer-bottom-ellipse-losange" />
      </div>
    </>
  );
}

export default LayerExoPlanete;
