import { Swiper, SwiperSlide } from 'swiper/react';
import MemberCard from '@components/MemberCard';

type MemberType = {
  image: string;
  name: string;
  insta: string | null;
  linkedin: string | null;
  tiktok: string | null;
  description: string;
};

function MembersSectionMobile({
  members,
}: {
  members: MemberType[];
}): JSX.Element {
  return (
    <div className="flex md:space-x-8 space-x-3 items-stretch z-30 w-full">
      <Swiper
        slidesPerView={1.25} // afficher partiellement les diapositives suivante et précédente
        centeredSlides // centrer la diapositive active
        spaceBetween={20} // ajouter un peu d'espace entre les diapositives
      >
        {members.map((member) => (
          <SwiperSlide key={member.name}>
            <MemberCard
              image={member.image}
              name={member.name}
              insta={member.insta}
              linkedin={member.linkedin}
              tiktok={member.tiktok}
              description={member.description}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default MembersSectionMobile;
