import { Swiper, SwiperSlide } from 'swiper/react';
import TitleTag from '@components/TitleTag';
import OfferCard, { IOfferPointType } from '@components/OfferCard';
import React, { useContext, useEffect } from 'react';
import { generateStars } from './HomeSection';
import { GlobalContext } from '../context/globalContext';

const pointsEssential: IOfferPointType[] = [
  {
    text: "2h d'appel vidéo/semaine",
    type: 'basic',
  },
  {
    text: 'Accès à vie au Discord',
    type: 'basic',
  },
  {
    text: 'Accès aux coachs',
    type: 'basic',
  },
  {
    text: 'Cursus Économie',
    type: 'basic',
  },
  {
    text: 'Cursus Psychologie',
    type: 'basic',
  },
  {
    text: 'Cursus Technique',
    type: 'basic',
  },
  {
    text: 'Cursus Quantification',
    type: 'basic',
  },
];

const pointsPremium: IOfferPointType[] = [
  {
    text: "5h d'appel vidéo/semaine",
    type: 'premium',
  },
  {
    text: 'Mentorat premium',
    type: 'premium',
  },
  {
    text: 'Mastermind présentiel',
    type: 'premium',
  },
  {
    text: 'Accès à vie au Discord',
    type: 'basic',
  },
  {
    text: 'Accès aux coachs',
    type: 'basic',
  },
  {
    text: 'Cursus Économie',
    type: 'basic',
  },
  {
    text: 'Cursus Psychologie',
    type: 'basic',
  },
  {
    text: 'Cursus Technique',
    type: 'basic',
  },
  {
    text: 'Cursus Quantification',
    type: 'basic',
  },
];

function OffersSection(): JSX.Element {
  const { isMobile } = useContext(GlobalContext);
  const starsContainer = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (starsContainer && starsContainer.current) {
      generateStars(isMobile ? 30 : 50, starsContainer.current); // 50 étoiles, ajustez selon vos besoins
    }
  }, []);
  return (
    <div
      id="offers-section"
      className="w-full flex flex-col items-center relative overflow-hidden md:pt-[7.5rem] pt-[4rem] md:pb-[9.375rem] pb-[4rem] z-40"
    >
      <div
        ref={starsContainer}
        className="absolute top-0 left-0 right-0 h-1/2"
      />
      <div>
        <TitleTag text="Explorez" />
      </div>
      <div className="text-center md:mt-4 mt-8 space-y-8 mb-[4rem]">
        <div className="md:text-[4rem] text-[2rem] leading-normal font-medium">
          Nos offres
        </div>
        <p className="md:text-2xl text-sm font-light md:w-[63rem] w-[23.5rem]">
          Plongez dans le monde de l’économie et de la finance avec nos
          programmes de coaching sur mesure, élaborés pour vous guider vers
          l’excellence en finance de marché.
        </p>
      </div>
      {isMobile ? (
        <div className="w-full flex justify-center h-full">
          <Swiper
            slidesPerView={1.5} // afficher partiellement les diapositives suivante et précédente
            centeredSlides // centrer la diapositive active
            spaceBetween={20} // ajouter un peu d'espace entre les diapositives
          >
            <SwiperSlide>
              <OfferCard title="Programme Standard" points={pointsEssential} />
            </SwiperSlide>
            <SwiperSlide>
              <OfferCard title="Programme Premium" points={pointsPremium} />
            </SwiperSlide>
          </Swiper>
        </div>
      ) : (
        <div className="w-full flex justify-center space-x-8">
          <OfferCard title="Programme Standard" points={pointsEssential} />
          <OfferCard title="Programme Premium" points={pointsPremium} />
        </div>
      )}
    </div>
  );
}

export default OffersSection;
