import React from 'react';

function TitleTag({ text }: { text: string }): JSX.Element {
  return (
    <div className="w-min whitespace-nowrap px-4 py-2 border border-orange-opacity-80 md:text-xl text-xs font-light z-30 rounded-[2rem]">
      {text}
    </div>
  );
}

export default TitleTag;
