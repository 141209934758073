import { motion } from 'framer-motion';
import TitleTag from '@components/TitleTag';
import { useEffect, useRef } from 'react';

function VisionSection(): JSX.Element {
  const visionDescriptionContainerRef = useRef<HTMLDivElement>(null);
  const visionDescriptionPRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && visionDescriptionContainerRef.current) {
          visionDescriptionContainerRef.current.classList.add(
            'animate-vision-description'
          );
        } else if (visionDescriptionContainerRef.current) {
          visionDescriptionContainerRef.current.classList.remove(
            'animate-vision-description'
          );
        }
      });
    });

    if (visionDescriptionPRef.current) {
      observer.observe(visionDescriptionPRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      id="vision-section"
      className="w-full flex flex-col items-center md:h-[50.875rem] h-[26rem] relative overflow-hidden md:pt-[7.5rem] pt-[2.5rem]"
    >
      <div className="vision-section bg-black-default" />
      <div className="vision-section-layer vision-section-layer-gradient" />
      <motion.div
        className="vision-section-layer bg-black"
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        transition={{ duration: 2, delay: 1 }}
      />
      <TitleTag text="À travers" />
      <div className="relative z-[25] text-center md:w-auto w-full">
        <div className="md:text-[4rem] text-[2rem] leading-normal md:mt-4 mt-8 font-medium z-30">
          Notre mission
        </div>
        <div className="text-orange-default md:text-[15.625rem] text-[6rem] font-medium !z-[40] md:leading-none leading-normal">
          éclairer
        </div>
        <div
          className="vision-description z-[35] flex flex-col items-center"
          ref={visionDescriptionContainerRef}
        >
          <div className="absolute top-0 left-0 w-full h-[1px] vision-description-top-line" />
          <p
            className="md:w-[63.25rem] md:text-3xl text-sm font-light"
            ref={visionDescriptionPRef}
          >
            Dans l’univers complexe de la finance de marché, la clarté n’est pas
            seulement un atout mais une nécessité.
          </p>
        </div>
        <div className="flou z-[25]">Vision</div>
      </div>
    </div>
  );
}

export default VisionSection;
