import { useContext } from 'react';
import { InstagramLogo, TiktokLogo } from '@assets/svgComponents';
import { GlobalContext } from '../context/globalContext';

function FooterSection(): JSX.Element {
  const { isMobile } = useContext(GlobalContext);
  return (
    <div className="w-full flex flex-col items-center relative overflow-hidden z-40">
      <div className="footer-top-line" />
      <div className="w-full md:px-[12.5rem]">
        <div className="flex md:flex-row flex-col md:justify-between justify-center items-center w-full pt-12 font-light">
          <div className="text-[2rem]">The Truth</div>
          {isMobile && (
            <div className="w-full px-[2.875rem]">
              <div className="footer-middle-line my-[2.625rem]" />
            </div>
          )}
          <div className="flex space-x-12 text-xl">
            <a
              target="_blank"
              href="https://www.tiktok.com/@thetruthmentoring?_t=8gSJisSMXBY&_r=1"
              rel="noreferrer"
            >
              <TiktokLogo height="2rem" width="2rem" />
            </a>
            <a
              target="_blank"
              href="https://instagram.com/thetruthmentoring?igshid=MzRlODBiNWFlZA=="
              rel="noreferrer"
            >
              <InstagramLogo height="2rem" width="2rem" />
            </a>
          </div>
        </div>
        {!isMobile && <div className="footer-middle-line my-[2.625rem]" />}
        <div className="flex w-full font-light md:justify-start justify-center md:mt-0 mt-[2.625rem]">
          © 2023 <span className="font-normal mx-1">The Truth™️</span>
        </div>
      </div>
    </div>
  );
}

export default FooterSection;
