import { CheckCircleIcon } from '@assets/svgComponents';
import ShinyButton from '@components/ShinyButton';
import { useContext } from 'react';
import { GlobalContext } from '../context/globalContext';

function DescriptionSection(): JSX.Element {
  const { isMobile } = useContext(GlobalContext);

  const checkIcon = isMobile ? (
    <div className="w-[1.5rem]">
      <CheckCircleIcon width="1rem" height="1rem" fill="white" />
    </div>
  ) : (
    <div className="w-[1.5rem]">
      <CheckCircleIcon width="1.5rem" height="1.5rem" fill="white" />
    </div>
  );

  return (
    <div
      id="description-section"
      className="flex flex-col items-center md:px-[1rem] mx-[2rem] relative md:pb-[15.625rem] pt-[2rem]"
    >
      <div className="customers-issues md:w-[63.25rem]">
        <div className="flex space-x-4 items-center">
          {checkIcon}
          <p className=" md:text-2xl text-sm font-light">
            Vous voulez vous lancer en{' '}
            <span className="font-normal text-orange-default">
              finance de marché
            </span>{' '}
            mais vous ne savez pas comment structurer votre parcours
            d&apos;apprentissage pour des{' '}
            <span className="font-semibold">résultats concrets</span>.
          </p>
        </div>
        <br />
        <div className="flex space-x-4 items-center">
          {checkIcon}
          <p className="md:w-[63.25rem] md:text-2xl text-sm font-light">
            Vous débutez dans{' '}
            <span className="font-normal text-orange-default">
              l&apos;investissement
            </span>{' '}
            et cherchez à adopter des décisisons plus stratégiques et{' '}
            <span className="font-semibold">moins hasardeuses</span>.
          </p>
        </div>
        <br />
        <div className="flex space-x-4 items-center">
          {checkIcon}
          <p className="md:w-[63.25rem] md:text-2xl text-sm font-light">
            Vous souhaitez maximiser vos{' '}
            <span className="font-normal text-orange-default">
              gains financiers
            </span>
            , mais vous ne savez pas comment analyser les aspects{' '}
            <span className="font-semibold">psychologiques et techniques </span>
            du marché afin d&apos;identifier les bonnes opportunités.
          </p>
        </div>
        <br />
        <div className="flex space-x-4 items-center">
          {checkIcon}
          <p className="md:w-[63.25rem] md:text-2xl text-sm font-light">
            Vous êtes un{' '}
            <span className="font-normal text-orange-default">
              investisseur expérimenté
            </span>{' '}
            et souhaitez améliorer votre stratégie pour{' '}
            <span className="font-semibold">booster vos performances</span>.
          </p>
        </div>
      </div>
      <div className="proposal mt-[4rem] text-center">
        <div className="w-full h-[1px] vision-description-top-line mb-[3rem]" />
        <p className="md:w-[63.25rem] md:text-2xl text-sm font-light">
          Chez{' '}
          <span className="font-normal text-orange-default">The Truth</span>,
          nous transformons{' '}
          <span className="font-semibold">
            la complexité des marchés financiers en opportunité
          </span>
          , en fournissant des connaissances approfondies et des outils
          essentiels pour obtenir des rendements significatifs.
          <br />
          <br />
          Apprenez à décoder les{' '}
          <span className="font-semibold">signaux économiques</span> et à
          comprendre
          <span className="font-semibold"> la psychologie du marché</span> pour
          des investissements judicieux.
          <br />
          <br />
          Avec nous, levez l&apos;incertitude et{' '}
          <span className="font-normal text-orange-default">
            transformez la connaissance en profit
          </span>
          .
        </p>
      </div>
      <div className="contact-logos flex items-center pt-[3rem]">
        <ShinyButton
          text="Get started"
          onClick={() => {
            window.open(
              'https://calendly.com/thetruthmentoring/30min',
              '_blank'
            );
          }}
        />
      </div>
    </div>
  );
}

export default DescriptionSection;
