import { FireIcon } from '@assets/svgComponents';
import { motion } from 'framer-motion';
import React, { useContext, useEffect } from 'react';
import { GlobalContext } from '../context/globalContext';

export const generateStars = (numStars: number, container: Element) => {
  for (let i = 0; i < numStars; i += 1) {
    const star = document.createElement('div');
    star.className = 'star';

    const xPos = `${Math.random() * 100}%`;
    const yPos = `${Math.random() * 100}%`;
    star.style.left = xPos;
    star.style.top = yPos;

    if (Math.random() > 0.9) {
      star.classList.add('star--big');
    }

    if (Math.random() > 0.1) {
      star.classList.add('star--twinkle');
      star.style.animationDelay = `${Math.random() * 5}s`; // génère un délai aléatoire entre 0 et 2 secondes
    }

    container.appendChild(star);
  }
};

function HomeSection(): JSX.Element {
  const { isMobile } = useContext(GlobalContext);
  const homeContainer = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (homeContainer && homeContainer.current) {
      generateStars(isMobile ? 30 : 50, homeContainer.current); // 50 étoiles, ajustez selon vos besoins
    }
  }, []);

  return (
    <div
      ref={homeContainer}
      className="home-container w-full md:mt-[15.5rem] mt-[10.95rem] mb-[7.5rem] z-10 relative"
      id="home-section"
    >
      <motion.div
        className="w-full pt-12"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 3 }}
      >
        <div className="text-center md:text-[4rem] text-[2rem] font-normal md:mb-12 mb-8">
          Décryptez les marchés,
          <br />
          Créez votre capital
        </div>
        <div className="flex justify-center">
          <div className="font-light md:text-[1.75rem] text-[1rem] md:w-[45rem] w-[17rem] text-center">
            <span className="font-normal">The Truth</span> est votre allié
            stratégique pour maîtriser la finance de marché et atteindre vos
            objectifs d&apos;investissement.
          </div>
        </div>
        <div className="flex justify-center mt-10">
          <div className="inline-flex md:py-2 md:px-4 py-1 px-2 justify-center items-center md:gap-4 gap-2 background-cta border border-orange-default rounded-[2rem]">
            <FireIcon width={isMobile ? '1.5rem' : '1rem'} height="1.5rem" />
            <p className="md:text-[1.75rem] text-xs font-light">
              <a href="#offers-section">Découvrez les solutions de The Truth</a>
            </p>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default HomeSection;
