import React, { useEffect, useMemo, useState } from 'react';

import NeonEffect from '@components/NeonEffect';
import LayerExoPlanete from '@components/LayerExoPlanete';
import Menu from '@components/menu/Menu';
import { GlobalContext } from './context/globalContext';
import HomeSection from './sections/HomeSection';
import VisionSection from './sections/VisionSection';
import MembersSection from './sections/MembersSection';
import OffersSection from './sections/OffersSection';
import ContactSection from './sections/ContactSection';
import FooterSection from './sections/FooterSection';
import DescriptionSection from './sections/DescriptionSection';

function Home(): JSX.Element {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const globalContextValues = useMemo(
    () => ({
      isMobile,
      updateIsMobile: setIsMobile,
    }),
    [isMobile]
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < 768);
    });

    return () => {
      window.removeEventListener('resize', () => {
        setIsMobile(window.innerWidth < 768);
      });
    };
  }, []);

  return (
    <GlobalContext.Provider value={globalContextValues}>
      <Menu />
      <NeonEffect />
      <div className="min-h-screen w-full flex flex-col overflow-hidden">
        <div className="flex-grow w-full flex flex-col">
          <HomeSection />
          <VisionSection />
          <DescriptionSection />
          <MembersSection />
          <div className="relative overflow-hidden pb-[5rem]">
            <LayerExoPlanete />
            <OffersSection />
            <ContactSection />
            <FooterSection />
          </div>
        </div>
      </div>
    </GlobalContext.Provider>
  );
}

export default Home;
