function ShinyButton({
  text,
  onClick,
}: {
  text: string;
  onClick?: () => void;
}) {
  return (
    <button
      type="button"
      className="w-full py-4 px-8 border border-orange-default background-cta flex justify-center items-center rounded-lg cursor-pointer relative overflow-hidden focus:outline-none"
      onMouseEnter={(e) => {
        const shineDiv = e.currentTarget.querySelector('.shine-effect');
        if (shineDiv) {
          shineDiv.animate(
            [
              { transform: 'translateX(-100%)', opacity: 0 },
              { opacity: 0.5 },
              { transform: 'translateX(100%)', opacity: 0 },
            ],
            {
              duration: 500,
              iterations: 1,
              fill: 'forwards',
            }
          );
        }
      }}
      onClick={onClick}
    >
      <div className="md:text-xl text-base font-light z-10 whitespace-nowrap">
        {text}
      </div>
      <div
        className="shine-effect absolute top-0 left-0 h-full bg-gradient-to-r from-transparent via-white to-transparent skew-x-12"
        style={{ width: '100%', opacity: 0 }}
      />
    </button>
  );
}

export default ShinyButton;

ShinyButton.defaultProps = {
  onClick: undefined,
};
